import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
import ContactEmail from "@components/Contact/ContactEmail"


const SocialLinks = () => {
  // Sort the nav menu items using the order field
  const data = useStaticQuery(graphql`
    {
      allStrapiSocial {
        edges {
          node {
            id
            title
            url
          }
        }
      }
    }
  `)

  const socialLinks = data.allStrapiSocial

  return (
    <div className="social-links">
      <h3>Let's build something great together:</h3>
      <ContactEmail />
      <p>Or connect with me on:</p>
      {socialLinks.edges.map(item => {
        const socialItem = item.node

        return (

          <a
            key={socialItem.id}
            href={socialItem.url}
            target="_blank"
            title={socialItem.title}
            aria-label={socialItem.title}
            rel="noopener noreferrer"
          >
            {/*
            {item.node.icon &&
              <Img
                fluid={item.node.icon.childImageSharp.fluid}
                alt={item.node.title}
              />
            }
          */}
            {socialItem.title &&
              <strong>{socialItem.title}</strong>
            }
          </a>
        )}
      )}
    </div>
  )
}

export default SocialLinks