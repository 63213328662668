import React, { useState } from "react"

const ContactEmail = () => {

  const [emailAddress, setEmailAddress] = useState(`kyleATklmcgregorDOTcom`);
  const handleClick = () => setEmailAddress(emailAddress.replace(/AT/, `@`).replace(/DOT/, `.`));

  return (
    <a
      id="contact-email"
      href={`mailto:${emailAddress}`}
      onClick={handleClick}
      target="_blank"
      rel="noreferrer"
      title="Email me"
      aria-label="Email me"
    >
      <strong>Email me</strong>
    </a>
  )
}


export default ContactEmail