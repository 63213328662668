import React from "react"
import { graphql } from "gatsby"
// import ReactMarkdown from "react-markdown"
import Layout from "@components/layout"
import SEO from "@components/seo"
import Footer from "@components/Footer/Footer"
import SocialLinks from "@components/Nav/SocialLinks"
import Bell from "@static/icons/ships-bell.svg"

export const query = graphql`
  query ContactPageQuery($id: Int) {
    strapiContact(strapiId: { eq: $id }) {
      strapiId
      title
      subtitle
      content
      description
    }
  }
`

const ContactPage =  ({ data }) => {
  const contact = data.strapiContact
  const title = contact.title ? contact.title : ""
  const description =contact.description ? contact.description : ""
  // const subtitle = contact.subtitle ? contact.subtitle : ""
  // const content = contact.content ? contact.content : ""

  return (
    <Layout>
      <main className="grid-content">
        <div className="grid-content-inner">
          <div id="contact" className="grid-container page">
            <SEO
              title={title}
              description={description}
            />
            <Bell />
            <h1>{title}</h1>
            {/*<h2>{subtitle}</h2>
            <ReactMarkdown children={content} />*/}
            <SocialLinks />
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  )
}

export default ContactPage
